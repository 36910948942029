//@import url('./reset.scss');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
  
img {
	max-width: 100%;
	height: auto;
}

.App {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 380px; 
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  background-color: #0e002e;
  background-image: linear-gradient(315deg, #0d0825 0%, #402da3 70%);
}

.header {
  margin: 5px;
  margin-bottom: -4px;
}

.mHeader {
  display: none;
}

.container {
    display: flex;
    width: 95%;
    margin: 5px;
    min-height: 500px;
    background-color:rgba(176, 179, 204, 0.521);
    -moz-border-radius: 8px;
    border-radius: 8px;     
    border: black solid 2px;
}

.pikBody {
  width: 55%;
  height: 100%;
  margin-top: 80px;
  min-height: 510px;
  min-width: 520px;
  max-height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(160, 173, 250, 0.411);
  border: rgba(0, 0, 0, 0.507) solid 3px;
  background-image: url('./assets/ScreenBackground.png'); 
  box-shadow: 1px 1px 0px rgba(46, 45, 45, 0.651),
  3px 3px 0px rgba(21, 21, 22, 0.541);  
  z-index: 1; 
}

.noticeHolder {
  display: flex;
  padding: 5px;
  color: #FFF;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  width: 100%;  
}

.logoHolder {
  
  justify-self: center;
  width: 100%;
  height: auto;
}

.notice {
  color: #FFF;
  font-size: 28px;
  text-align: center; 
  line-height: 42px;
}

.mFooter {
  display: none;
  padding: 5px;
  color: #FFF;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 100%;  
}

a {
  color: #FFF;
  text-decoration: none;

  :hover {
    color: rgb(158, 158, 158);
  }
}

.logo {
  max-width: 100%;
  height: auto;
  width: auto;
}

.footer {
  padding: 5px;
  color: #FFF;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.theater {
  position: fixed;
  width: 100%;
  z-index: 100;
  bottom: -50px;
}

.seats {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.seats li {
  width: 100%;
  height: 90px;
  margin: 0px 2px;
  background: #7c0000;
  background-image: linear-gradient(315deg, #4b0000 0%, #be0000 90%);
  border-radius: 12%;
  border: #000 solid 1px;
}

.textShadow {
  text-shadow: 
  2px 0 0 #000,
  -2px 0 0 #000,
  0 2px 0 #000, 
  0 -2px 0 #000,
  1px 1px #000,
  -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000;
}






@media only screen and (max-width: 600px) {
  .container 
  {
    width: 100%;
    border-radius: 0px;
    border: none;
  }

  .pikBody { 
    height: 100%;
    max-height: 100%;   
    min-width: 395px; 
  }  

  .footer {
    display: none;
  }

  .mFooter {
    display: block;
  }

  .mHeader {
    display: block;
  }

  .header {
    display: none;
  }

  .mHeader {
    width: 100%;
    text-align: center;
    background: rgb(44, 1, 126);

    img {
      width: 60%;
    }
  }


  .fpWindow {
    flex: 1 1;
    border-radius: 0px !important;
    border: none !important;
    padding-bottom: 15px;
  }

  .container {
    border-radius: 0px !important;
    margin: 0px;
  }

  .footer {
    display: none;
  }  

  .logoCopy {
    display: none !important; 
  }

  .seats {
    display: none;
  }


  .header {
    margin: 5px;
    margin-bottom: -8px;
  }
  
}

@media only screen and (max-height: 655px)
{
  .seats {
    display: none;
  }
}